import React from 'react'
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import * as firebase from "firebase/app";
import app from 'firebase/app'
import "firebase/auth";
import {
    FirebaseAuthProvider,
    FirebaseAuthConsumer,
    IfFirebaseAuthed,
    IfFirebaseAuthedAnd
} from "@react-firebase/auth";

import { signInWithGoogle } from "../service/configfirebase"

import { UserContext } from '../providers/user.provider';

export function Navigate() {

    // const user = useContext(UserContext)


    return <header className="flex space-between align-center">

        <ul className="nav flex">
            {/* <li><a href="#online">Online Shops</a></li>
            <li><a href="#map">Map</a></li> */}

            {/* <li><a href="#about">About Us</a></li> */}
        </ul>
        <h1 className="logo" >האורבניסטית- מקור הידע לאופנה יד שניה</h1>
    </header>

}

