import React from 'react'


export function Preview({ shop }) {

    // style={{ backgroundImage: `url(${shop.image})` }}
    return <div className="box flex column space-between" >
        <a href={shop.url} target="_blank" >{/* <h1>{shop.title}</h1> */}
            <div> <img src={shop.image} alt="" /></div>
            {/* <h4>{shop.title}</h4> */}
        </a>
    </div>

}





