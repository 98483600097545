import firebase from 'firebase'
import 'firebase/auth'
import 'firebase/firebase-firestore'
export var firebaseConfig = {
    apiKey: "AIzaSyB7u7tbsyba0RBz77nKgYm4CB0M4lmIspM",
    authDomain: "second-hand-5cfe0.firebaseapp.com",
    databaseURL: "https://second-hand-5cfe0-default-rtdb.firebaseio.com",
    projectId: "second-hand-5cfe0",
    storageBucket: "second-hand-5cfe0.appspot.com",
    messagingSenderId: "697882998731",
    appId: "1:697882998731:web:917cdb33da0c966f94684e",
    measurementId: "G-0SX7GVLTZK"
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export default db;
export const auth = firebase.auth();
const googleProvider = new firebase.auth.GoogleAuthProvider()
export const signInWithGoogle = () => {
    auth.signInWithPopup(googleProvider).then((res) => {
        console.log(res.user)
    }).catch((error) => {
        console.log(error.message)
    })
}
// export const environment = {
//     production: false,
//     firebaseConfig: {
//         apiKey: "AIzaSyB7u7tbsyba0RBz77nKgYm4CB0M4lmIspM",
//         authDomain: "second-hand-5cfe0.firebaseapp.com",
//         databaseURL: "https://second-hand-5cfe0-default-rtdb.firebaseio.com",
//         projectId: "second-hand-5cfe0",
//         storageBucket: "second-hand-5cfe0.appspot.com",
//         messagingSenderId: "697882998731",
//         appId: "1:697882998731:web:917cdb33da0c966f94684e",
//         measurementId: "G-0SX7GVLTZK"
//     }
// };

