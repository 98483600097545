import React from 'react'

export function Footer() {

    return <footer className="flex  align-center">

        {/* <div className="logo">Secondhand-IL</div> */}

        {/* <div>shfa.israel@gmail.com</div> */}
        <div>Copyright © 2021 Haurbanist. • haurbanist@gmail.com • www.haurbanist.com</div>

    </footer>

}

