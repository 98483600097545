import React, { Component } from 'react'
import './assets/scss/main.scss';
import { Second } from './pages/Second.jsx';
import { FirebaseDatabaseProvider } from "@react-firebase/database";
import { Navigate } from './cmps/Navbar';

import { Footer } from './cmps/Footer';
import SignIn from './cmps/Login';
import { Route, Switch } from 'react-router';
import { HashRouter } from 'react-router-dom';

import withFirebaseAuth from 'react-with-firebase-auth'
import firebase from 'firebase/app';
import 'firebase/auth';
import { firebaseApp } from './service/configfirebase';
import UserProvider from "./providers/user.provider";

const firebaseAppAuth = firebaseApp.auth();
const providers = {
    googleProvider: new firebase.auth.GoogleAuthProvider(),
};

export class App extends Component {

    render() {

        return (
            <FirebaseDatabaseProvider>
                {/* <UserProvider> */}
                <Navigate />

                <Switch>

                    <Route component={Second} path="/" />

                </Switch>
                {/* <Contact /> */}
                <Footer />
                {/* </UserProvider> */}
            </FirebaseDatabaseProvider >
        )
    }


}
