import React from 'react'
import { Preview } from './Preview';

export function List({ shops }) {
    return shops.map((shop) => {
        return (

            <Preview shop={shop} key={shop.url} />
        )

    })
}

