import React, { useState, useEffect } from 'react'
import { List } from '../cmps/List.jsx';

import db from '../service/configfirebase.js';


export function Second() {
    // const { eventies } = useSelector(state => state.eventiModule)

    const [shops, setShops] = useState([])
    useEffect(() => {
        fetchBlogs();
    }, [])

    const fetchBlogs = async () => {
        const response = db.collection('shops');
        const data = await response.get();
        var shops = data.docs.map(item => {
            return item.data()
        })
        setShops(shops)
    }


    return (<section>
        <div className="container" >
            <div className="list-container"><h1 id="online">חנויות אונליין</h1>   <div className="list-grid"><List shops={shops} /></div></div>
            <div className="flex column" >
                <h1 id="map">מפת החנויות</h1>
                <iframe className="map"
                    loading="lazy"
                    src="https://www.google.com/maps/d/embed?mid=1SUvis3yEj2vPvBAUm-IUooO6M5vAWvCw" width="100%" height="480"></iframe></div>
        </div>
    </section>

    )
}

